<script setup lang="ts">
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import Avatar from '~/pages/components/Avatar.vue'

const civiqaUser = useCiviqaUser()

const navItems = [
    {
        title: 'Search',
        href: '/legacy/bills/search',
    },
    {
        title: 'Federal',
        href: '/legacy/country:us/gov/latest',
        prefixes: ['/legacy/country:us/gov'],
    },
    {
        title: 'States',
        href: '/legacy/states',
        prefixes: ['/legacy/country:us/state:', '/legacy/states'],
    },
    // {
    //     title: 'Interests',
    //     href: '/interests',
    // },
    {
        title: 'Officials',
        href: '/legacy/persons',
    },
]
</script>

<template>
    <!-- <Disclosure
        v-slot="{ _ }"
        as="nav"
        class="sticky top-0 z-20 bg-green-800"
    > -->
    <Disclosure
        as="nav"
        class="sticky top-0 z-20 bg-green-800"
    >
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div class="flex h-16 items-center justify-between">
                <div class="flex items-center">
                    <div class="shrink-0">
                        <NuxtLink to="/">
                            <img
                                class="h-8 w-auto"
                                src="/logo.png"
                                alt="CEQAbase"
                            >
                        </NuxtLink>
                    </div>

                    <div class="hidden sm:ml-6 sm:block">
                        <div class="flex space-x-4">
                            <a
                                v-for="item in navItems"
                                :key="item.title"
                                :href="item.href"
                                class="rounded-md px-3 py-2 text-sm font-medium  text-white"
                                :class="[
                                    (item.prefixes ? item.prefixes.some(prefix => $route.path.startsWith(prefix))
                                        : $route.path.startsWith(item.href))
                                        ? 'bg-green-900' : 'hover:bg-green-700 hover:text-white',
                                ]"
                            >
                                {{ item.title }}
                            </a>
                        </div>
                    </div>
                </div>

                <div class="sm:ml-6 sm:block">
                    <div class="flex items-center">
                        <!-- Profile dropdown -->
                        <Menu
                            v-if="civiqaUser?.isFirebaseAnonymous === false"
                            as="div"
                            class="relative ml-3"
                        >
                            <div>
                                <MenuButton
                                    class="relative flex rounded-full bg-gray-800 text-sm outline-none ring-2 ring-green-600 ring-offset-2 ring-offset-green-700"
                                >
                                    <span class="absolute -inset-1.5" />

                                    <span class="sr-only">Open user menu</span>

                                    <Avatar
                                        class="size-8"
                                        :photo-url="civiqaUser?.photoUrl"
                                        :name="civiqaUser?.fullName"
                                    />
                                </MenuButton>
                            </div>

                            <transition
                                enter-active-class="transition ease-out duration-100"
                                enter-from-class="transform opacity-0 scale-95"
                                enter-to-class="transform opacity-100 scale-100"
                                leave-active-class="transition ease-in duration-75"
                                leave-from-class="transform opacity-100 scale-100"
                                leave-to-class="transform opacity-0 scale-95"
                            >
                                <MenuItems
                                    class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                >
                                    <MenuItem>
                                        <span class="block px-4 py-2 text-sm text-gray-700">{{ civiqaUser.email }}</span>
                                    </MenuItem>

                                    <MenuItem>
                                        <a
                                            href="/logout"
                                            class="block px-4 py-2 text-sm text-gray-700"
                                        >Log out</a>
                                    </MenuItem>
                                </MenuItems>
                            </transition>
                        </Menu>

                        <div
                            v-else
                            class="flex items-center"
                        >
                            <NuxtLink
                                :to="{ name: 'login', query: { redirect: $route.path } }"
                                class="ml-8 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-orange-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-orange-700"
                            >
                                Sign up
                            </NuxtLink>
                        </div>
                    </div>
                </div>
                <!-- <div class="-mr-2 flex sm:hidden">
                    Mobile menu button
                    <DisclosureButton
                        class="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                        <span class="absolute -inset-0.5" />
                        <span class="sr-only">Open main menu</span>
                        <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
                        <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
                    </DisclosureButton>
                </div> -->
            </div>
        </div>

        <DisclosurePanel class="sm:hidden">
            <div class="border-t border-gray-700 pb-3 pt-4">
                <div
                    v-if="civiqaUser?.isFirebaseAnonymous === false"
                    class="flex items-center px-5"
                >
                    <div class="shrink-0">
                        <Avatar
                            :photo-url="civiqaUser?.photoUrl"
                            :name="civiqaUser?.fullName"
                            class="size-10"
                        />
                    </div>

                    <div class="ml-3">
                        <div class="text-base font-medium text-white">
                            {{ civiqaUser?.fullName }}
                        </div>

                        <div class="text-sm font-medium text-gray-400">
                            {{ civiqaUser?.email }}
                        </div>
                    </div>
                </div>

                <div class="mt-3 space-y-1 px-2">
                    <DisclosureButton
                        as="a"
                        href="#"
                        class="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                    >
                        Log out
                    </DisclosureButton>
                </div>
            </div>
        </DisclosurePanel>
    </Disclosure>
</template>
